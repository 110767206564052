@import "bulma";
@import "./node_modules/leaflet/dist/leaflet.css";
@import "./fonts/stylesheet.css";

body {
    font-family: ralewaysemibold;
    font-weight: 700;
    text-shadow: rgba(0, 0, 0, .2) 2px 2px 4px;
  /*  background-image: url('images/grid.png');*/
    scroll-behavior: smooth;
}

.section {
    min-height: 100vh;
    margin-top: 15px;
}

#map {
    height: 60vh;
    z-index: -100;
}
/*
.fade-image {
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.0    ));
}*/

.hero-body > div:nth-child(1) {
    opacity: 1;
}

.container > .fade-image {

}

.container {    
    width: 85%;
    max-width: 800px;
}

@media all and (max-width:900px) {
  .container {
        width: 100%;
    }
}

.hero-body {
    background-image: url(images/wusch.jpg);
    background-position: center center;
    background-repeat: no-repeat;
}

.image100 {
    width: 100%;
}

.footer-container {
    padding: 3rem 1.5rem;
}

.margin-null-auto {
    margin: 0 auto;
}


