/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 15, 2018 */



@font-face {
    font-family: 'ralewaybold';
    src: url('raleway-bold-webfont.woff2') format('woff2'),
         url('raleway-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'ralewayextrabold';
    src: url('raleway-extrabold-webfont.woff2') format('woff2'),
         url('raleway-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'ralewayextralight';
    src: url('raleway-extralight-webfont.woff2') format('woff2'),
         url('raleway-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'ralewayheavy';
    src: url('raleway-heavy-webfont.woff2') format('woff2'),
         url('raleway-heavy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'ralewaylight';
    src: url('raleway-light-webfont.woff2') format('woff2'),
         url('raleway-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'ralewaymedium';
    src: url('raleway-medium-webfont.woff2') format('woff2'),
         url('raleway-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'ralewayregular';
    src: url('raleway-regular-webfont.woff2') format('woff2'),
         url('raleway-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: ralewaysemibold;
    src: url('raleway-semibold-webfont.woff2') format('woff2'),
         url('raleway-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'ralewaythin';
    src: url('raleway-thin-webfont.woff2') format('woff2'),
         url('raleway-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}